import { loader } from "./loader";
import { closeIconUrl, playerUrl } from "./init";
let isLoading = true;
export let popupDiv = null;
let backdrop;

export const getBackdrop = (quizConfig) => {
  backdrop = document.createElement("div");
  backdrop.id = "iframe-backdrop";
  const styles = {
    position: "fixed",
    fontSize: "14px",
    width: "100vw",
    height: "100vh",
    top: "0",
    left: "0",
    overflow: "hidden",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "9999999999",
    // maxHeight: "-webkit-fill-available",
    boxSizing: "border-box"
  };
  clearStyles(backdrop);
  setStyles(backdrop, styles);

  if(quizConfig) {
    setStyles(backdrop, {visibility: 'hidden', pointerEvents: 'none', opacity: '0'});
  }
  return backdrop;
};

const getClose = () => {
  const close = document.createElement("button");
  close.id = "iframe-close";
  let closeStyles = {
    border: "none",
    position: "absolute",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "5px",
    cursor: "pointer",
    opacity: "0",
    transition: "opacity 1000ms linear",
    right: "15px",
    top: "15px",
  };

  if (window.innerWidth > 800) {
    closeStyles.right = "-24px";
    closeStyles.top = "-24px";
  }

  clearStyles(close);
  setStyles(close, closeStyles);

  close.innerHTML = `<img style="display: block" src="${closeIconUrl}" title="close btn" />`;

  return close;
};

export const getPopup = (quizConfig) => {
  if(!quizConfig) {
    document.body.style.overflow = "hidden";
    document.getElementsByTagName('html')[0].style.overflow = "hidden";
  }
  
  const backdrop = getBackdrop(!!quizConfig);

  const div = document.createElement("div");
  div.style.position = "absolute";
  div.style.top = "50%";
  div.style.left = "50%";
  div.style.width = "100%";
  div.style.maxWidth = "1000px";
  div.style.height = "300px";
  div.style.transform = "translate(-50%, -50%)";
  div.style.overflow = "hidden";

  isLoading = true;
  const loading = loader();

  const iframe = document.createElement("iframe");
  iframe.id = "popup-iframe";
  const iframeStyles = {
    border: "none",
    borderRadius: "10px",
    width: "100%",
    height: "0px",
    opacity: "0",
    transition: "opacity 1000ms linear",
  };

  clearStyles(iframe);
  setStyles(iframe, iframeStyles);

  window.boostingleadData = quizConfig

  iframe.src = playerUrl;

  const close = getClose();
  close.onclick = () => {
    if(quizConfig) {
      const triggerElem = document.getElementById('boostinglead-global-wrapper')

      if(triggerElem) {
        triggerElem.style.display = 'flex'
      }
      setStyles(backdrop, {visibility: 'hidden', pointerEvents: 'none', opacity: '0'});
    }else {
      backdrop.remove();
    }
    
    window.removeEventListener("resize", setPopupSize);
    document.body.style.overflow = null;
    document.getElementsByTagName('html')[0].style.overflow = null;
  };

  div.append(loading);
  div.append(close);
  div.append(iframe);
  popupDiv = div;

  backdrop.append(div);

  window.addEventListener("resize", setPopupSize);
  setPopupSize();

  return backdrop;
};

export const onMessageIframe = (data) => {
  const {
    data: { type, height },
  } = data;
  const div = popupDiv;
  if (!div) return;

  const loading = popupDiv.querySelector("#preloader");
  const iframe = popupDiv.querySelector("#popup-iframe");
  const close = popupDiv.querySelector("#iframe-close");

  if (type === "onload") {
    loading?.remove();
    isLoading = false;
    iframe.style.opacity = "1";
    div.style.overflow = null;
    close.style.opacity = "1";
  }
  if (type === "onload" || type === "onresize") {
    if (window.innerWidth > 800) {
      iframe.style.height = `${height}px`;
      div.style.height = null;
    } else {
      div.style.width = "100%";
      div.style.height = window.innerHeight + "px"; //"100vh";
      iframe.style.height = "100%";
      iframe.style.borderRadius = "0px";
      iframe.style.left = "0px";
    }
  }
};
export const setPopupSize = () => {
  const windowWidth = window.innerWidth;
  const div = popupDiv;
  if (!div) return;
  const iframe = popupDiv.querySelector("#popup-iframe");
  

  if (windowWidth < 800) {
    backdrop.style.height = window.innerHeight + "px";
    div.style.width = "100%";
    div.style.height = "100%";
    iframe.style.height = window.innerHeight + "px"; //"100%";
  } else if (!isLoading) {
    backdrop.style.height = "100vh";
    div.style.height = null;
    // if (iframe.contentWindow) {
    //   const iframeBody = iframe.contentWindow.document.querySelector("body");
    //   iframe.style.height = `${
    //     iframeBody.querySelector("#root > *")?.clientHeight
    //   }px`;
    // }
  }
};

export function setStyles(el, styles) {
  for (const key in styles) {
    el.style[key] = styles[key];
  }
}
export function clearStyles(el) {
  el.removeAttribute("style");
}


export function showPopupIsInline() {
  setStyles(backdrop, {visibility: 'visible', pointerEvents: 'auto', opacity: '1'});

  const triggerElem = document.getElementById('boostinglead-global-wrapper')

  if(triggerElem) {
    triggerElem.style.display = 'none'
  }

  document.body.style.overflow = "hidden";
  document.getElementsByTagName('html')[0].style.overflow = "hidden";
}