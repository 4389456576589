import { onMessageIframe } from "./common";
export let playerUrl = null;
export const playerBaseUrl = process.env.REACT_APP_PLAYER_BASE_URL;
const apiBaseUrl = process.env.REACT_APP_BASE_URL;

export const closeIconUrl = `${playerBaseUrl}/images/closeIframe.svg`;
export const btnIcon = `${playerBaseUrl}/images/starBtn.svg`;

const getPlayerUrl = (quizId, isPreview, session) => {
  let params = [`parentUrl=${encodeURIComponent(window.location.origin)}`];
  //if (!isPreview) {
    params.push(`session=${session}`);
  //}

  return `${playerBaseUrl}/${quizId}${
    params.length > 0 ? "?" + params.join("&") : ""
  }`;
};

export function init(quizId, isPreview, session) {
  window.removeEventListener("message", onMessageIframe, false);
  window.addEventListener("message", onMessageIframe, false);

  playerUrl = getPlayerUrl(quizId, isPreview, session);
}

export async function getConfig(quizId, service_session) {
  //console.log({quizId})
  const localKey = `boostinglead-quiz-session-${quizId}`;
  let sessionKey = localStorage.getItem(localKey);
  if(sessionKey == "undefined"){
    sessionKey = undefined;
  }
  const resp = await fetch(`${apiBaseUrl}/client-sessions/`, {
    method: "POST",
    body: JSON.stringify(
      sessionKey ? { quiz: quizId, session: sessionKey, service_session } : { quiz: quizId, service_session }
    ),
    headers: { "Content-Type": "application/json" },
  });
  const result = await resp.json();
  sessionKey = result.session;
  if(!sessionKey){
    throw  result.detail; //"Not publish";
  }
  localStorage.setItem(localKey, sessionKey);

  const res = await fetch(`${apiBaseUrl}/quiz_config_public/${sessionKey}/`);
  init(quizId, false, sessionKey);
  
  return await res.json();
}
