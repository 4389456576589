import { loader } from "./loader";
import { playerUrl } from "./init";

export const showLanding = () => {
  const iframe = document.createElement("iframe");
  let innerBody = null;

  let isLoading = true;
  const loading = loader();

  return {
    isInit: false,
    init: function (body, quizConfig, isPreview) {
      innerBody = body;
      innerBody
        .querySelectorAll("*")
        .forEach((item) => (item.style.display = "none"));

      const width = 850;
      iframe.style.border = "none";
      iframe.style.width = "100%";
      iframe.style.minHeight = "560px";

      iframe.src = playerUrl;

      iframe.onload = () => {
        const iframeBody = iframe.contentWindow.document.querySelector("body");
        setTimeout(() => {
          loading.remove();
          isLoading = false;

          iframe.style.height = `${
            iframeBody.querySelector("#root > *")?.clientHeight
          }px`;
        }, 10);
        //setTimeout(() => loading.remove());
      };

      innerBody.append(loading);
      innerBody.append(iframe);
      this.isInit = true;
    },
    update: (body, quizConfig) => {},
    remove: function () {
      innerBody
        .querySelectorAll("*")
        .forEach((item) => (item.style.display = null));
      iframe.remove();
      this.isInit = false;
    },
  };
};
