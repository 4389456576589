import { getPopup, setStyles } from "./common";


export const showPopup = () => {
  let backdrop = null;
  let timeout = null;

  const showPopup = () => {
    setStyles(backdrop, {visibility: 'visible', pointerEvents: 'auto', opacity: '1'});
  }

  return {
    isInit: false,
    init: function (body, quizConfig, isPreview) {
      backdrop = getPopup(quizConfig);
      body.append(backdrop);

      this.isInit = true;

      if(isPreview || quizConfig.popup_type === 'regular' || quizConfig.popup_type === '') {
        timeout = setTimeout(showPopup, isPreview ? 0 : (quizConfig.popup_opening_time ?? 0) * 1000);

        return
      }
      
      if(quizConfig.popup_type === 'button') {
        document.addEventListener('click', e => {
          if(e.target && e.target.closest("#boostinglead-popup-btn")) {
            showPopup()
          }
        })
      }
      
    },
    update: function (body, quizConfig) {},
    remove: function () {
      backdrop.remove();
      if (timeout) clearTimeout(timeout);
      timeout = null;
      this.isInit = false;
    },
  };
};
